import { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    FileContainer,
    FileLabel,
    FileNameContainer,
    RemoveIcon,
} from "./style";
import plusIcon from "../../assets/images/plus-icon.png";

type SimpleFileUploadProps = {
    title: string;
    multiple?: boolean;
    onFileSelected: (file: any) => void;
    clearFiles: boolean;
};

export default function SimpleFileUpload(props: SimpleFileUploadProps) {
    const { t } = useTranslation();
    const inputRef = createRef<HTMLInputElement>();
    const [fileNames, setFileNames] = useState<any[]>([]);
    const [base64Files, setBase64Files] = useState<any[]>([]);

    useEffect(() => {
        if (props.clearFiles) {
            resetFileInput();
            setFileNames([]);
            setBase64Files([]);
            props.onFileSelected([]);
        }
    }, [props.clearFiles]);

    const getBase64 = (file: any) => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(String(reader.result));
            reader.onerror = (error) => reject(error);
        });
    };

    const chooseFile = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files: FileList | null = event?.target?.files;

        if (files) {
            const base64Promises = Array.from(files).map((file) => getBase64(file));

            Promise.all(base64Promises)
                .then((base64Array) => {
                    const newFileNames = Array.from(files).map((file) => file.name);
                    
                    // Modificação feita aqui
                    setBase64Files(base64Array); 
                    setFileNames(newFileNames);
                    props.onFileSelected(base64Array);

                    // Clear input to allow re-upload of the same file (modificação aqui)
                    if (inputRef.current) {
                        inputRef.current.value = ""; 
                    }
                })
                .catch((error) => {
                    console.error("Error converting files to base64:", error);
                });
        }
    };

    const removeFile = (index: number) => {
        const newFileNames = fileNames.filter((_, i) => i !== index);
        const newBase64Files = base64Files.filter((_, i) => i !== index);

        setFileNames(newFileNames);
        setBase64Files(newBase64Files);
        props.onFileSelected(newBase64Files);
    };

    const resetFileInput = () => { // Nova função adicionada
        setFileNames([]);
        setBase64Files([]);
        props.onFileSelected([]);
        if (inputRef.current) {
            inputRef.current.value = ""; // Modificação feita aqui
        }
    };

    return (
        <FileContainer onClick={chooseFile}>
            <input
                onChange={handleFile}
                type="file"
                style={{ display: "none" }}
                ref={inputRef}
                multiple={props.multiple}
                accept=".pdf"
            />

            <p>{props.title}</p>

            <img style={{ marginTop: 20 }} src={plusIcon} alt="Plus Icon" />
            <FileLabel>{t("choose-file")}</FileLabel>
            {fileNames.map((text, index) => (
                <FileNameContainer key={index}>
                    <p>
                        <RemoveIcon
                            onClick={(e: any) => {
                                e.stopPropagation();
                                removeFile(index);
                            }}
                        >
                            {text}
                            &nbsp;- x
                        </RemoveIcon>
                    </p>
                </FileNameContainer>
            ))}
        </FileContainer>
    );
}