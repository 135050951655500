import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from "formik";
import CoordinateInput from "@/components/CoordinateInput";
import SimpleFileUpload from "../../components/upload/SimpleFileUpdate";

import {
    Button,
    Center,
    Container,
    FileListContainer,
    Input,
    InputContainer,
    InputError,
    InputLabel,
    Main,
    Select,
    Title,
    Textarea,
} from "./style";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useServiceProperty } from "../../hooks/serviceProperty";
import { useNavigate } from "react-router-dom";
import FileUploadSquare from "../../components/upload/fileUploadSquare";
import { useServiceHome } from "../../hooks/serviceHome";
import { PropertyLocationSelector } from "../../components/location-selector/property-location-selector";

export default function RegisterProperty() {
    const { createProperty } = useServiceProperty();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [files, setFiles] = useState({
        file_deed_number: "",
        file_cib: "",
        file_proof_of_residence: "",
        file_images: "",
        file_updated_property_registration: "",                 
        file_encumbrance_certificate: "",                     
        file_power_of_attorney: "",                    
        file_itr_debt_clearance_certificate: "",                        
        file_thirty_years_certificate: "",              
        file_ibama_negative_certificate: "",                     
    });
    const [buttonSave, setButtonSave] = useState(t("save"));
    const { getVegetations } = useServiceHome();
    const [vegetations, setVegetations] = useState<any[]>([]);
    const [clearFilesUpload, setClearFilesUpload] = useState(false);
    const [catalog, setCatalog] = useState("sell");
    const [latitude, setLatitude] = useState('');   // Estado local para latitude
    const [longitude, setLongitude] = useState('');

    useEffect(() => {
        async function getVegetationsAll() {
            await getVegetations()
                .then((response: any) => {
                    setVegetations(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        getVegetationsAll();
    }, []);

    const initialValues = {
        catalogs: "sell",
        name: "",
        deed_number: "",
        acre: "",
        cib_nirf_number: "",
        cost: "",
        description: "",
        vegetation: "dense",
        address: "",
        lat: "",
        lng: "",
        carbon_credits: "",
    };

    const handleLatitudeChange = (value: string) => {
        setLatitude(value);  // Atualiza o valor de latitude
    };

    const handleLongitudeChange = (value: string) => {
        setLongitude(value);  // Atualiza o valor de longitude
    };

    const convertDMSToDecimal = (dms: string): number => {
        const [degrees, minutes, seconds, direction] = dms.match(/(\d+)[° ](\d+)'(\d+)?''? ([NSEW])/i)?.slice(1) || [];
    
        if (!degrees || !minutes || !direction) {
            throw new Error('Formato inválido');
        }
    
        const decimal = parseFloat(degrees) + (parseFloat(minutes) / 60) + (parseFloat(seconds || '0') / 3600);
        
        // Ajusta o sinal para o hemisfério
        return direction === 'S' || direction === 'W' ? -decimal : decimal;
    };


    const formValidation = (values: typeof initialValues) => {};

    const handleSubmit = async (
        values: typeof initialValues,
        actions: FormikHelpers<typeof initialValues>
    ) => {
        setButtonSave(`${t("waiting")}...`);
        values.catalogs = catalog;
        // Tente converter latitude e longitude no formato DMS para decimal
        let latDecimal = latitude; // Valor inicial em DD
        let lngDecimal = longitude; // Valor inicial em DD
    
        try {
            // Tente converter a latitude de DMS para decimal
            latDecimal = convertDMSToDecimal(latitude).toString();
            lngDecimal = convertDMSToDecimal(longitude).toString();
        } catch (error) {
            console.warn("Latitude não pôde ser convertida; mantendo o formato existente.", error);
        }

        values.cost = "0";
        values.carbon_credits = "0";

        await createProperty({
            ...values,
            ...files,
            lat: latDecimal,
            lng: lngDecimal,
            
        } as any)
        .then((response: any) => {
            if (catalog === "sell") {
                toast.warning(t("property-analysis"), {
                    position: "top-center",
                });
            } else {
                toast.success(t("successfully"), {
                    position: "top-center",
                });
            }
            setTimeout(() => {
                navigate("/mySpaceSeller/environmental-portfolio");
            }, 4000);
    
            setClearFilesUpload(true);
        })
        .finally(() => {
            setButtonSave(`${t("save")}`);
        });
    };

    const onFileSelected = (name: string) => (file: string) => {
        setFiles(prevFiles => ({ ...prevFiles, [name]: file }));
    };


    return (
        <Container>
            <Navbar />
            <ToastContainer />
            <Main>
                <Title>{t("register-property")}</Title>
                <Formik
                    validate={formValidation}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <InputContainer>
                            <div
                                style={{
                                    width: "100%",
                                    borderBottom: "1px solid #eee",
                                    paddingBottom: "30px",
                                    marginBottom: "30px",
                                }}
                            >
                                <InputLabel>{t("how_register")}</InputLabel>
                                <Field
                                    as={Select}
                                    type="text"
                                    name="catalog"
                                    required
                                    onChange={(event: any) => {
                                        const value = event.target.value;
                                        setCatalog(value);
                                    }}
                                >
                                    <option key={"sell"} value={"sell"}>
                                        {t("i-seller")}
                                    </option>
                                    <option key={"catalog"} value={"catalog"}>
                                        {t("i-catalog")}
                                    </option>
                                </Field>
                            </div>
                        </InputContainer>
                        <InputContainer>
                            <div
                                style={{
                                width: "100%",
                                paddingBottom: "30px",
                                marginBottom: "30px",
                            }}
                            >
                                <InputLabel>{t("name")}</InputLabel>
                                <Field
                                    as={Input}
                                    type="text"
                                    name="name"
                                    minLength={6}
                                    required
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc'
                                    }}
                                />
                                <InputError>
                                    <ErrorMessage name="name" />
                                </InputError>

                                <InputLabel>{t("hectares")}</InputLabel>
                                <Field
                                    as={Input}
                                    type="text"
                                    name="acre"
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc'
                                    }}
                                />
                                <InputError>
                                    <ErrorMessage name="acre" />
                                </InputError>

                                <InputLabel>{t("number-doc")}</InputLabel>
                                <Field
                                    as={Input}
                                    type="text"
                                    name="cib_nirf_number"
                                    /*required={catalog === "sell"}*/
                                    disabled={catalog === "catalog"}
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc'
                                    }}
                                />
                                <InputError>
                                    <ErrorMessage name="cib_nirf_number" />
                                </InputError>
                                <InputLabel>{t("Latitude")}</InputLabel>
                                <Field
                                    name="lat"
                                    component={CoordinateInput}
                                    value={latitude} // Usa o estado local
                                    placeholder="Ex: 12°34'56'' N"
                                    onChange={handleLatitudeChange}
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc'
                                    }} 
                                />
                                <InputError>
                                    <ErrorMessage name="lat" />
                                </InputError>

                                <InputLabel>{t("Longitude")}</InputLabel>
                                <Field
                                    name="lng"
                                    component={CoordinateInput}
                                    value={longitude} // Usa o estado local
                                    placeholder="Ex: 123°45'67'' W"
                                    onChange={handleLongitudeChange}
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc'
                                    }}
                                />
                                <InputError>
                                    <ErrorMessage name="lng" />
                                </InputError>
                            </div>

                            <div                           
                            style={{
                                width: "100%",
                                paddingBottom: "30px",
                                marginBottom: "30px",
                            }}
                            >
                            <PropertyLocationSelector />
                                <InputLabel>{t("vegetation")}</InputLabel>
                                <Field
                                    as={Select}
                                    type="text"
                                    name="vegetation"
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc'
                                    }}
                                    
                                >
                                    {vegetations.map((v) => (
                                        <option key={v.slug} value={v.slug}>
                                            {t(`vegetation-${v.slug}`)}
                                        </option>
                                    ))}
                                </Field>
                                <InputError>
                                    <ErrorMessage name="vegetation" />
                                </InputError>
                                <InputLabel>{t("deed-number")}</InputLabel>
                                <Field
                                    as={Input}
                                    type="text"
                                    name="deed_number"
                                    /*required={catalog === "sell"}*/
                                    disabled={catalog === "catalog"}
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc'
                                    }}
                                />
                                <InputError>
                                    <ErrorMessage name="deed_number" />
                                </InputError>
                            </div>
                        </InputContainer>
                        <InputContainer>
                            <div style={{ height: "auto" }}>
                                <InputLabel>{t("description")}</InputLabel>
                                <Field as={Textarea} name="description" />
                                <InputError>
                                    <ErrorMessage name="description" />
                                </InputError>
                            </div>
                        </InputContainer>

                        <InputContainer>
                            <div>
                                <InputLabel>{t("file")}</InputLabel>
                                <FileListContainer>
                                    {catalog === "sell" && (
                                        <>
                                            <SimpleFileUpload
                                                title="CCIR"
                                                onFileSelected={onFileSelected(
                                                    "file_cib"
                                                )}
                                                clearFiles={clearFilesUpload}
                                            />
                                            <SimpleFileUpload
                                                title={t("proof-residence")}
                                                onFileSelected={onFileSelected(
                                                    "file_proof_of_residence"
                                                )}
                                                clearFiles={clearFilesUpload}
                                            />
                                            <SimpleFileUpload
                                                title={t("deed-file")}
                                                onFileSelected={onFileSelected(
                                                    "file_deed_number"
                                                )}
                                                clearFiles={clearFilesUpload}
                                            />
                                        <SimpleFileUpload
                                            title={t("image-property")}
                                            multiple={true}
                                            onFileSelected={onFileSelected(
                                                "file_images"
                                            )}
                                            clearFiles={clearFilesUpload}
                                        />
                                        <SimpleFileUpload
                                            title={t("image-property")}
                                            multiple={true}
                                            onFileSelected={onFileSelected(
                                                "file_images"
                                            )}
                                            clearFiles={clearFilesUpload}
                                        />
                                        <SimpleFileUpload
                                            title={t("file_current_registration")}
                                            onFileSelected={onFileSelected("updated_property_registration")}
                                            clearFiles={clearFilesUpload}
                                        />
                                        <SimpleFileUpload
                                            title={t("file_certificate_liens")}
                                            onFileSelected={onFileSelected("encumbrance_certificate")}
                                            clearFiles={clearFilesUpload}
                                        />
                                        <SimpleFileUpload
                                            title={t("file_power_of_attorney")}
                                            onFileSelected={onFileSelected("power_of_attorney")}
                                            clearFiles={clearFilesUpload}
                                        />
                                        <SimpleFileUpload
                                            title={t("file_itr_certificate")}
                                            onFileSelected={onFileSelected("itr_debt_clearance_certificate")}
                                            clearFiles={clearFilesUpload}
                                        />
                                        <SimpleFileUpload
                                            title={t("file_thirty_years_certificate")}
                                            onFileSelected={onFileSelected("thirty_years_certificate")}
                                            clearFiles={clearFilesUpload}
                                        />
                                        <SimpleFileUpload
                                            title={t("file_ibama_certificate")}
                                            onFileSelected={onFileSelected("ibama_negative_certificate")}
                                            clearFiles={clearFilesUpload}
                                        /> 
                                    </>
                                )}
                                </FileListContainer>
                            </div>
                        </InputContainer>

                        <Center>
                            <Button type="submit">{buttonSave}</Button>
                        </Center>
                    </Form>
                </Formik>
            </Main>

            <Footer />
        </Container>
    );
}
