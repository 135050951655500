import React from "react";
import styled from "styled-components";
import colors from "../../../utils/colors";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
}

const ModalOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  border-radius: 8px;
`;

const ModalTitle = styled.h2`
  margin-bottom: 10px;
  font-family: "Josefin Bold";
  color: #000;
`;

const ModalMessage = styled.p`
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const CloseButton = styled.button`
  background-color: ${colors.primary};
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-family: "Josefin Bold";
  color: #fff;
`;

const CancelButton = styled.button`
  background-color: transparent;
  color: ${colors.primary};
  padding: 10px 20px;
  border: 1px solid ${colors.primary};
  cursor: pointer;
  border-radius: 4px;
`;

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
}) => {
  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContent>
        <ModalTitle>{title}</ModalTitle>
        <ModalMessage>{message}</ModalMessage>
        <ButtonContainer>
          <CloseButton onClick={onConfirm}>Enviar</CloseButton>
          <CancelButton onClick={onClose}>Cancelar</CancelButton>
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
};