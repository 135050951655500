import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding: 2rem; /* Usar rem para espaçamento */
`;

export const ContentImg = styled.div`
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin: 4rem 0; /* Utilize rem para espaçamentos verticais */
`;

export const Logo = styled.div`
    width: 20rem; /* Largura usando rem */
    height: auto; /* Altura automática para manter a proporção do logo */

    @media (max-width: 670px) {
        width: 25rem; /* Ajusta para telas menores */
    }

    @media (max-width: 426px) {
        display: flex;
        max-width: 7.5rem; 
    }
`;

export const ContentLogin = styled.div`
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem; /* Usando rem em vez de px */
`;

export const ButtonLogin = styled.button`
    display: flex;
    gap: 4rem; /* Usando rem para espaçamento */

    @media (max-width: 590px) {
        flex-direction: column; /* Mantém os botões em coluna em telas pequenas */
    }
`;

export const ButtonPerson = styled.button`
    display: flex;
    width: 34rem; /* Usar rem em vez de px */
    height: 7rem; /* Usar rem em vez de px */
    border-radius: 0.625rem; /* equivalente a 10px */
    align-items: center;
    justify-content: center;
    font-size: 1.375rem; /* equivalente a 22px */
    color: #fff;
    flex-direction: column;
    font-family: "poppins";
    background-color: #5c8014;

    @media (max-width: 425px) {
        width: 10rem; /* Ajuste a largura */
        height: 5rem; /* Ajuste a altura */
        font-size: 0.5rem; /* equivalente a 17px */
    }

    @media (min-width: 426px) and (max-width: 590px) {
        width: 13rem; /* Ajuste a largura */
        height: 5rem; /* Ajuste a altura */
        font-size: 0.7rem; /* equivalente a 17px */
    }

    @media (min-width: 591px) and (max-width: 670px) {
        width: 10rem; /* Ajuste a largura apropriada */
        height: 5rem;
        font-size: 1rem; /* equivalente a 15px */
    }

    // Continue modificando conforme necessário para outros media queries
    
`;

export const ButtonSatellite = styled.button`
    display: flex;
    width: 34rem; /* Usar rem em vez de px */
    height: 7rem; /* Usar rem em vez de px */
    background-color: #5c8014;
    border-radius: 0.625rem; /* equivalente a 10px */
    align-items: center;
    justify-content: center;
    font-size: 1.375rem; /* equivalente a 22px */
    color: #fff;
    flex-direction: column;
    font-family: "poppins";

    @media (max-width: 425px) {
        width: 10rem; /* Ajuste a largura */
        height: 5rem; /* Ajuste a altura */
        font-size: 0.5rem; /* equivalente a 17px */
    }

    @media (min-width: 426px) and (max-width: 590px) {
        width: 23rem; /* Ajuste a largura */
        height: 5rem; /* Ajuste a altura */
        font-size: 1.125rem; /* equivalente a 18px */
    }
`;
