import styled from 'styled-components';

export const NotificationCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  margin: 8px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
`;

export const NotificationHeader = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
`;

export const NotificationBody = styled.div`
  margin-bottom: 8px;
`;

export const GoToPropertyButton = styled.button`
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

export const NotificationList = styled.ul`
  list-style-type: none;
  padding: 0;
`;