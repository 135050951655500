import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, GridContainerTotal, StatusLabel } from "./style";
import "./contract.css";
import { storageServerPath } from "../../../utils/server/api";
import { useAuth } from "../../../context/AuthContext";
import NavBar from "../../../components/navbar/navbar";
import { ToastContainer } from "react-toastify";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import Footer from "../../../components/footer/footer";
import { useParams } from "react-router-dom";
import { useServiceProperty } from "../../../hooks/serviceProperty";
import { AppProperty } from "../../../models/Property";
import { ContractActions } from '../contractAction'
import { ContractDownloadButton } from "./ContractDownloadButton";
import { FileListContainer } from "@/screens/update-property/style";

export default function Contract() {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>(); 
    const { getContractSeller, getProperty } = useServiceProperty(id);
    const { user } = useAuth();
    const [contract, setContract] = useState<string | undefined>(undefined); 
    const [file, setFile] = useState<string | undefined>(undefined); 
    const [property, setProperty] = useState<AppProperty | undefined>(undefined); // 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const propertyData = await getProperty(); // Obtenha a propriedade
                setProperty(propertyData);

                const contractData = await getContractSeller(propertyData.id); // Obtenha o contrato
                setContract(contractData.contract); // Aqui assume que contractData tenha um campo 'contract'
                setFile(contractData.file); // Aqui pode precisar ajustar como obtém o arquivo
            } catch (error) {
                console.error("Erro ao buscar dados:", error);
            }
        };
        fetchData();
    }, [id]);


    const getStatus = () => {
        switch (property?.status_contract) {
            case "0":
                return t("pending");
            case "1":
                return t("awaiting-approval");
            case "2":
                return t("signed");
            case "3":
                return t("canceled");
            default:
                return t("pending");
        }
    };
    const contractUrl = `${storageServerPath}${file}`/*.replace('localhost:3000', 'https://homologacao.ecolifemeioambiente.com.br');*/
    const assignedContractUrl = `${storageServerPath}${property?.contract}`/*.replace('localhost:3000', 'https://homologacao.ecolifemeioambiente.com.br');*/
    return (
        <Container>
            <NavBar />
            <ToastContainer />
            <Breadcrumb noHomeIcon itens={[t("property-details-contract")]} />
            <div style={{ padding: "10px 15px", width: "97%" }}>
                <StatusLabel>{t("contract-status")}: {getStatus()}</StatusLabel>
                <div className="divAlertContratoButtons" style={{ textAlign: "center" }}>
                Atenção: Para assinar o contrato, você precisa&nbsp;
                <FileListContainer>
                    <ContractDownloadButton title="Baixar Contrato" contractUrl={contractUrl} />
                    <ContractActions file={file} />
                    <ContractDownloadButton title="Baixar Contrato Assinado" contractUrl={assignedContractUrl} />
                </FileListContainer>
                {/* <FileListContainer>
                    <div>
                        <button className="button" onClick={() => {
                            window.open(`${storageServerPath}${file}`, "_blank");
                        }}>
                            Baixar Contrato
                        </button>
                    </div>

                    <div>
                        <button className="button">
                            Baixar Contrato Assinado
                        </button>
                    </div>
                </FileListContainer> */}
                    <div>
                        <button className="button" onClick={() => {
                            window.open("https://assinador.iti.br/assinatura/index.xhtml", "_blank");
                        }}>
                            Assinar no gov.br
                        </button>
                    </div>
                {/* . Após assinar, envie para o e-mail
                comercial@ecolifemeioambiente.com.br. */}
            </div>
                <GridContainerTotal>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: contract || "",
                        }}
                    />
                </GridContainerTotal>
            </div>
            <Footer />
        </Container>
    );
}
