import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../context/AuthContext'; 
import getNotifications, { Notification } from '../../../hooks/serviceNotifications'; 
import { useNavigate } from 'react-router-dom';
import { 
    NotificationCard, 
    NotificationHeader, 
    NotificationBody, 
    NotificationList 
} from '../../../components/notificationCard';
import {
    Button, Title
  } from './style';
import { useTranslation } from 'react-i18next';

const MySpaceNotifications: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { axiosConfig } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const data = await getNotifications(axiosConfig)();
        setNotifications(data);
      } catch (error) {
        setError('Failed to load notifications');
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [axiosConfig]);

  if (loading) return <div>{t("loading")}</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      {/* <Title>{t("my-notifications")}</Title> */}
      {notifications.length === 0 ? (
        <p>Você não possui notificações!</p>
      ) : (
        <NotificationList>
          {notifications.map(notification => (
            <li key={notification.id}>
              <NotificationCard>
                <NotificationHeader>{notification.message || 'Sem mensagem'}</NotificationHeader>
                <NotificationBody>
                  <p><strong>Propriedade notificada:</strong> {notification.property.name}</p>
                </NotificationBody>
                <Button onClick={() => navigate(`/property/${notification.property.id}`)}>
                  Ir para a propriedade
                </Button>
              </NotificationCard>
            </li>
          ))}
        </NotificationList>
      )}
    </div>
  );
}

export default MySpaceNotifications;