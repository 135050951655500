import { useTranslation } from "react-i18next";
import {
    FileContainer,
    FileLabel,
} from "../../../components/upload/style";
import downloadImg from "../../../assets/images/cloud-computing.png";

type ContractDownloadButtonProps = {
    title: string;         // Título do botão
    contractUrl: string;   // URL do contrato para download
};

export function ContractDownloadButton({ title, contractUrl }: ContractDownloadButtonProps) {
    const { t } = useTranslation();

    // Função que lida com o clique para abrir a URL do contrato
    const handleDownloadClick = (event: React.MouseEvent) => {
        event.stopPropagation(); 
        window.open(contractUrl, "_blank"); // Abre a URL em uma nova aba
    };

    return (
        <FileContainer onClick={handleDownloadClick} style={{ cursor: "pointer" }}>
            <p>{title}</p>
            <img style={{ marginTop: 20 }} src={downloadImg} alt="Download Icon" />
            <FileLabel>{t("Baixe aqui seu contrato")}</FileLabel>
        </FileContainer>
    );
}