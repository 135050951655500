import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { parseProperty, parsePropertyList, Property } from "../models/Property";
import { apiBase } from "../utils/server/api";
import { UserContractResponse } from "../models/UserResponse";

export type PropertyCreate = {
    name: string;
    deed_number: string;
    acre: string;
    cib_nirf_number: string;
    cost: string;
    description: string;
    vegetation: string;
    address: string;
    file_deed_number: string;
    file_cib: string;
    file_proof_of_residence: string;
    file_images: string;
    lat:string;
    lng:string;
    updated_property_registration: string; 
    encumbrance_certificate: string;        
    power_of_attorney: string;              
    itr_debt_clearance_certificate: string; 
    thirty_year_certificate?: string;        
    ibama_negative_certificate: string; 
};

const createProperty =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) =>
    async (data: PropertyCreate) => {
        try {
            const response = await axios.post(
                `${apiBase}/property`,
                data,
                config()
            );
            return parseProperty(response.data as Property);
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

const updateProperty =
    (config: ReturnType<typeof useAuth>["axiosConfig"], id?: any) =>
    async (data: Partial<PropertyCreate>) => {
        try {
            const response = await axios.post(
                `${apiBase}/property/update/${id}`,
                data,
                config()
            );
            return parseProperty(response.data as Property);
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

const fetchProperties =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) => async () => {
        return await axios
            .get(`${apiBase}/property/all`, config())
            .then((response: any) => {
                return parsePropertyList(response.data as Property[]);
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const getProperty =
    (config: ReturnType<typeof useAuth>["axiosConfig"], id?: any) =>
    async () => {
        return await axios
            .get(`${apiBase}/property/${id}`, config())
            .then((response: any) => {
                return parseProperty(response.data as Property);
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const removeFile =
    (
        config: ReturnType<typeof useAuth>["axiosConfig"],
        id?: any,
        file?: any,
        column?: any
    ) =>
    async (data: { file: string; column: string }) => {
        return await axios
            .post(`${apiBase}/property/file-delete/${id}`, data, config())
            .then((response: any) => {
                return parseProperty(response.data as Property);
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const removeProperty =
    (config: ReturnType<typeof useAuth>["axiosConfig"], id?: any) =>
    async () => {
        return await axios
            .delete(`${apiBase}/property/${id}`, config())
            .then((response: any) => {
                return true;
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const getArea =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) => async () => {
        return await axios
            .get(`${apiBase}/property/get-area`, config())
            .then((response: any) => {
                return parsePropertyList(response.data as Property[]);
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const openGetArea =
    (config: ReturnType<typeof useAuth>["headerConfig"]) => async () => {
        return await axios
            .get(`${apiBase}/property/open-get-area`, config())
            .then((response: any) => {
                return parsePropertyList(response.data as Property[]);
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const engineerConnect =
    (config: ReturnType<typeof useAuth>["axiosConfig"], id?: any) =>
    async () => {
        return await axios
            .post(`${apiBase}/property/engineer-connect/${id}`, {}, config())
            .then((response: any) => {
                return parseProperty(response.data as Property);
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const engineerDisconnect =
    (config: ReturnType<typeof useAuth>["axiosConfig"], id?: any) =>
    async () => {
        return await axios
            .post(`${apiBase}/property/engineer-disconnect/${id}`, {}, config())
            .then((response: any) => {
                return parseProperty(response.data as Property);
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const getAreaEngineer =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) => async () => {
        return await axios
            .get(`${apiBase}/property/get-area-engineer`, config())
            .then((response: any) => {
                return parsePropertyList(response.data as Property[]);
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const getCatalog =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) => async () => {
        return await axios
            .get(`${apiBase}/property/catalog`, config())
            .then((response: any) => {
                return parsePropertyList(response.data as Property[]);
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const getContractSeller =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) =>
    async (propertyId: any) => {
        try {
            const response = await axios.get(
                `${apiBase}/property/contracts-seller/${propertyId}`,
                config()
            );
            return response.data as UserContractResponse;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const uploadContract = async (
        config: ReturnType<typeof useAuth>["axiosConfig"],
        propertyId: string,
        formData: FormData
    ) => {
        try {
            const response = await axios.post(
                `${apiBase}/property/upload-contract/${propertyId}`,
                formData,
                {
                    headers: {
                        ...config().headers,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error("Erro no upload do contrato:", error);
            throw error;
        }
    };



export function useServiceProperty(id?: any, file?: any, column?: any) {
    const { axiosConfig } = useAuth();

    return {
        createProperty: createProperty(axiosConfig),
        fetchProperties: fetchProperties(axiosConfig),
        getProperty: getProperty(axiosConfig, id),
        removeFile: removeFile(axiosConfig, id, file, column),
        updateProperty: updateProperty(axiosConfig, id),
        removeProperty: removeProperty(axiosConfig, id),
        getArea: getArea(axiosConfig),
        engineerConnect: engineerConnect(axiosConfig, id),
        engineerDisconnect: engineerDisconnect(axiosConfig, id),
        openGetArea: openGetArea(axiosConfig),
        getAreaEngineer: getAreaEngineer(axiosConfig),
        getCatalog: getCatalog(axiosConfig),
        getContractSeller: getContractSeller(axiosConfig),
        uploadContract: (formData: FormData) => uploadContract(axiosConfig, id, formData),
    };
}
