import React from 'react';
import { Input } from '../screens/update-property/style'

interface CoordinateInputProps {
    value: string;  // O valor passado
    placeholder: string;  // Placeholder para o campo
    onChange: (value: string) => void;  // Função para lidar com mudanças
}

const CoordinateInput: React.FC<CoordinateInputProps> = ({ value, placeholder, onChange }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value.toUpperCase().replace(/[^0-9NSEW]/g, ''); // Remove caracteres indesejados
        
        // Formatação para graus, minutos e segundos
        let formatted = '';
        
        if (newValue.length > 0) {
            formatted += `${newValue.slice(0, 3)}°`; // Permitindo até 3 dígitos para os graus
        }
        if (newValue.length > 3) {
            formatted += `${newValue.slice(3, 5)}'`; // Minutos
        }
        if (newValue.length > 5) {
            formatted += `${newValue.slice(5, 7)}''`; // Segundos
        }
        if (newValue.length > 7) {
            formatted += ` ${newValue.slice(7)}`; // Direção
        }

        onChange(formatted);  // Chama a função de mudança passada
    };


    return (
      <div>
          <Input
              type="text"
              value={value} // Usa o valor passado do componente pai
              onChange={handleChange} // Alteração gerida aqui
              placeholder={placeholder}
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ccc'
            }}// Exemplo de estilo
          />
      </div>
  );
};

export default CoordinateInput;