import styled from "styled-components";
import colors from "../../utils/colors";

// Container principal da navbar
export const NavbarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;  // Usando rem para espaçamento
    border-bottom: 1px solid #ccc;
    flex-wrap: wrap; // Permite que os itens se movam para a próxima linha em telas menores
    background-color: #fff;
    @media (max-width: 425px) {
        display: flex;
        width: 100%; /* Ajuste a largura */
        height: 5rem; /* Ajuste a altura */
        font-size: 0.5rem; /* equivalente a 17px */
        flex-wrap: wrap;
    }
`;

export const Logo = styled.img`
    width: 15%; // Altera para % para uma melhor responsividade
    max-width: 180px; // Largura máxima
    height: auto; // Mantém a proporção
    @media (max-width: 425px){
    display: none;
    }
`;

// Estilo do botão de login
export const ButtonLogin = styled.button`
    background-color: ${colors.primary};
    border-radius: 4px;
    width: auto;  // Mudamos para auto para flexibilidade
    min-width: 80px; // Largura mínima
    height: 36px; // Altura fixa
    padding: 0.5rem; // Usar rem para espaçamento interno
    color: #fff; // Cor do texto
    font-size: 1rem; // Usando rem para consistência
    cursor: pointer; // Muda para pointer no hover
`;

// Informações do usuário
export const UserInfo = styled.div`
    display: flex;
    align-items: center;
`;

// Estilo da imagem de perfil
export const ProfileImage = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 0.5rem; // Usar rem para espaçamento
`;

// Contêiner para opções na navbar
export const OptionsContainer = styled.div`
    display: flex;
    justify-content: space-between; // Para espaço apropriado entre os elementos
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: ${colors.primary};
    width: 100%; // Largura total para preencher
    flex-wrap: wrap; // Permite flexibilidade em telas menores
`;

// Estilo para opções na navbar
export const Option = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 4px;
    width: 45%; // Define a largura como percentual
    margin: 0.5rem; // Adiciona margem para separar as opções
    padding: 1rem; // Adiciona espaçamento interno
`;

export const OptionText = styled.span`
    font-family: "poppins";
    font-size: 1rem; // Usando rem para consistência
`;

export const TitleInput = styled.h1`
    font-family: "poppins";
    color: #515151;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
`;

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 30px;
`;

export const Input = styled.input`
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    margin-right: 8px;
    font-size: 14px;
    width: 90%;

    ::placeholder {
        font-family: "poppins";
        color: "#dfcfcf";
    }
`;

export const SearchButton = styled.button`
    background-color: #6bca6e; /* Cor verde */
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px;
    width: 10%;
    justify-content: center;
    display: flex;
    align-items: center;
`;

export const Placeholder = styled.span`
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-family: "poppins";
    color: "#515151";
    pointer-events: none;
`;

export const Title = styled.h1`
    font-family: "poppins";
    color: #000;
    font-size: 22px;
`;

export const TextInformation = styled.h1`
    font-family: "poppins";
    color: #000;
    font-size: 15px;
`;

export const MarginRight = styled.div`
    margin-right: 20px;
`;

export const Button = styled.button`
    background-color: ${colors.primary};
    border-radius: 8px;
    height: 35px;
    width: 80%;
    border-right-color: "#000";

    font-family: "poppins";
    color: #fff;
`;

export const ButtonNotAccept = styled.button`
    background-color: ${colors.warning};
    border-radius: 8px;
    height: 35px;
    width: 80%;
    border-right-color: "#000";

    font-family: "poppins";
    color: #fff;
`;

export const ButtonCloseTerm = styled.button`
    background-color: ${colors.secondary};
    border-radius: 8px;
    height: 35px;
    width: 80%;
    border-right-color: "#000";

    font-family: "poppins";
    color: #fff;
`;

export const LanguageText = styled.p`
    font-family: "poppins";
    color: #000;
`;

export const Language = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
`;

export const Inputsearch = styled.input`
    width: 350px;
    height: 36px;
    border-bottom: 2px solid #95d2f1;
    background: none;
    outline: none;
    background-position: 10px center;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    padding: 0px 0px 0px 35px;
`;
export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    margin-left: 2rem;

    @media (max-width: 425px) {
        flex-wrap: wrap;
        margin-left: 0px;
        height: 200px;
        gap: 0.5rem;
        font-size: 0.5rem;
    }
`;
export const NavbarText = styled.a`
    cursor: pointer;
    color: #50700f;
    font-family: "poppins";
    font-size: 16px;
    &:hover {
        border-bottom: #96d2f4 solid 1px;
    }
    @media (max-width: 425px) {
    font-size: 0.5rem;
    }
`;

export const NavbarTextLogin = styled.a`
    cursor: pointer;
    color: #50700f;
    font-family: "poppins";
    font-size: 16px;

    &:hover {
        border-bottom: #96d2f4 solid 1px;
    }
    @media (max-width: 425px) {
    display: none;
    }
`;

export const SearchBar = styled.div`
    display: flex;
    color: #50700f;
`;

export const Welcome = styled.div`
    display: flex;
    margin-left: 70px;
    font-family: "poppins";
    font-size: 15px;
    align-items: center;
`;

export const Pointer = styled.span`
    cursor: pointer;
`;

export const Container = styled.div`
    align-items: center;
    position: relative;
    top: 0;
    z-index: 99;
    padding: 10px 20px;
    background-color: #fff;
    width: 100%;
    border-bottom: 1px solid #000000;
`;

export const MagnifierSearch = styled.img`
    width: 25px;
    margin: 10px 0px 0px 0px;
    position: absolute;
    cursor: pointer;
`;
export const Image = styled.img`
    width: 10px;
`;

export const DropdownLanguage = styled.select`
    display: flex;
    width: 72px;
    color: #50700f;
    font-family: "poppins";
    font-size: 16px;
    font-weight: 500;
    outline: none;
    border: white solid 1px;
    background-color: transparent;
`;

export const IconOrder = styled.div`
    border: 3px solid #50700f;
    border-radius: 50%;
    padding: 5px;
`;

export const ButtonNewProperty = styled.button`
    border: 3px solid #50700f;
    border-radius: 40px;
    font-family: "poppins";
    font-size: 13px;
    width: 100px;
    height: 45px;
    color: #50700f;
    cursor: pointer;
`;
