import { useState } from "react";
import FileUploadSquare from "../../components/upload/fileUploadSquare"; // Assumindo que está na mesma pasta

export function ContractActions({ file }:{ file?: string} ) {
    const [clearFiles, setClearFiles] = useState(false);

    const handleFileSelected = () => {
       console.log("File selected");
        
    };

    // const contractUrl = `${storageServerPath}${file}`.toString();
    // const property = { contract: file }; // Define 'property' with a valid structure
    // const assignedContractUrl = `${storageServerPath}${property.contract}`;


    return (
            <FileUploadSquare 
                title="Envio do Contrato em pdf, após assinado no gov.br"
                onFileSelected={handleFileSelected}
                clearFiles={clearFiles}
            />
    );
}