import styled from "styled-components";
import colors from "../../utils/colors";

export const Container = styled.div`
  width: 100%;
`;

export const Main = styled.div`
  margin-top: 40px;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 40px;
`;

export const Title = styled.h1`
  color: ${colors.black};
  font-size: 30px;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &::after {
    content: ' ';
    border-bottom: 4px solid ${colors.info};
    width: 60px;
  }
`;

export const Input = styled.input`
  border: 1px solid ${colors.black};
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  color: ${colors.gray};
`;

export const Select = styled.select`
  border: 1px solid ${colors.black};
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  color: ${colors.gray};
  background-color: white;
`;

export const Textarea = styled.textarea`
  border: 1px solid ${colors.black};
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  color: ${colors.gray};
  min-height: 200px;
`;


export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  & > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 10px;
    padding-left: 60px;
    padding-right: 60px;
    position: relative;

    &:first-child {
      padding-right: 70px;
    }
    &:last-child {
    }

    &:not(:last-child)::after {
      content: ' ';
      border-right: 4px solid ${colors.primary};
      height: 100%;
      position: absolute;
      right: 10px;
    }

    & > input, & > select, & > textarea {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
`;

export const InputLabel = styled.label`
  padding: 0px;
`;

export const InputError = styled.p`
  background-color: ${colors.danger};
  color: white;
  padding: 6px;
  margin-left: 20px;
  width: fit-content;
  &:empty {
    display: none;
  }
`;

export const ImageInput = styled.div`
  position:absolute;
  padding-top: 30PX;
  padding-left: 70px;

`;
export const Fieldd = styled.input`
  height: 200px;
  width: 296px;
  opacity: 0;
`;

export const ButtonFile = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Button = styled.button`
  background-color: ${colors.secondary};
  color: white;
  border-radius: 10px;
  padding: 10px;
  padding-left: 100px;
  padding-right: 100px;
`

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  height: 45px;
  left: 0;
  top: 0;
  width: 100%;
  height: 73px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

export const FileListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 10px;
  min-height: 300px;
`;
