import Navbar from "../../../components/navbar/navbar";
import { Container } from "./style";
import { useState, useEffect } from "react";
import Footer from "../../../components/footer/footer";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import MySpaceProfile from "./my-space-profile";
import MySpaceProperty from "./my-space-property";
import { useParams } from "react-router-dom";
import MySpaceBank from "./my-space-bank";
import "./menu.css";
import MySpaceContracts from "./my-space-contracts";
import MySpaceNotifications from "../seller/my-space-notifications";

export default function MySpaceSeller() {
    const { subpage } = useParams();
    const { t } = useTranslation();
    const [activeComponent, setActiveComponent] = useState( "MySpaceProfile");

    useEffect(() => {
        // Atualiza o activeComponent sempre que a subpage mudar
        setActiveComponent(subpage || "MySpaceProfile");
    }, [subpage]);

    const renderComponent = () => {
        switch (activeComponent) {
            case "MySpaceBank":
                return <MySpaceBank />;
            case "MySpaceProfile":
                return <MySpaceProfile />;
            case "MySpaceProperty":
                return <MySpaceProperty />;
            case "Contracts":
                return <MySpaceContracts />;
            case "MySpaceNotifications":
                return <MySpaceNotifications />;
            default:
                return <MySpaceProfile />;
        }
    };

    const renderTitle = () => {
        switch (activeComponent) {
            case "MySpaceBank":
                return t("bank-data"); // Texto em português para o título
            case "MySpaceProfile":
                return t("profile");
            case "MySpaceProperty":
                return t("my-properties");
            case "Contracts":
                return t("contracts");
            case "MySpaceNotifications":
                return t("my-notifications");
            default:
                return t("profile");
        }
    };


    return (
        <Container>
            <Navbar />
            <Breadcrumb itens={[t("my-space"), renderTitle()]} />

            <div className="container">
                <div className="bloco-menu">
                    <ul className="vertical-menu">
                        <li>
                            <a
                                href="#!"
                                onClick={() =>
                                    setActiveComponent("MySpaceProperty")
                                }
                            >
                                {t("my-properties")}
                            </a>
                        </li>
                        <li>
                            <a
                                href="#!"
                                onClick={() =>
                                    setActiveComponent("MySpaceProfile")
                                }
                            >
                                {t("profile")}
                            </a>
                        </li>
                        <li>
                            <a
                                href="#!"
                                onClick={() =>
                                    setActiveComponent("MySpaceBank")
                                }
                            >
                                {t("bank-data")}
                            </a>
                        </li>
                        <li>
                            <a
                                href="#!" 
                                onClick={() => setActiveComponent("MySpaceNotifications")}
                            >
                                {t("my-notifications")} 
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="bloco-corpo">
                    <h1>{renderTitle()}</h1> 
                    {renderComponent()} 
                </div>
            </div>

            <Footer />
        </Container>
    );
}
