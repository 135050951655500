import axios from "axios";
import { useAuth } from "../context/AuthContext"; // Importa o contexto de autenticação, se necessário
import { apiBase } from "../utils/server/api"; // Importa a base da URL da API

// Define o tipo de uma notificação
export interface Notification {
    property: any;
    id: number;
    createdAt: string;
    updatedAt: string;
    userId: number;
    propertyId: number;
    message: string;
}

// Função para obter notificações
const getNotifications =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) => async (): Promise<Notification[]> => {
        try {
            // Realiza a solicitação GET para obter notificações
            const response = await axios.get(`${apiBase}/user/notifications`, config());
            return response.data as Notification[];
        } catch (error) {
            console.error("Failed to fetch notifications:", error);
            throw error;
        }
    };

export default getNotifications;