import { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
    FileContainer,
    FileLabel,
    FileNameContainer,
    RemoveIcon,
} from "./style";
import { ConfirmationModal } from "../../screens/contracts/seller/ConfirmationModal";
import plusIcon from "../../assets/images/plus-icon.png";
import { useServiceProperty } from "../../hooks/serviceProperty";

type FileUploadSquareProps = {
    title: string;
    multiple?: boolean;
    onFileSelected: (file: any) => void;
    clearFiles: boolean;
    
};

export default function FileUploadSquare(props: FileUploadSquareProps) {
    const { t } = useTranslation();
    const inputRef = createRef<HTMLInputElement>();
    const [fileNames, setFileNames] = useState<any[]>([]);
    const [base64Files, setBase64Files] = useState<any[]>([]);    
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const { id } = useParams<{ id: string }>();
    const { uploadContract } = useServiceProperty(id);
    const [message, setMessage] = useState<{ text: string; color: string } | null>(null);


    useEffect(() => {
        if (props.clearFiles) {
            setFileNames([]);
            setBase64Files([]);
            props.onFileSelected([]);
            
        }
    }, [props.clearFiles]);

    const getBase64 = (file: any) => {
        return new Promise<string>((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(String(reader.result));
            };
            reader.onerror = function (error) {
                reject(error);
            };
        });
    };

    const chooseFile = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files: FileList | null = event?.target?.files;

        if (files && files[0]) {
            const file = files[0];

            // Define o arquivo selecionado e habilita o modal
            setSelectedFile(file);
            setFileNames([file.name]); // Altera o estado para exibir o nome do arquivo
            setModalOpen(true); // Abre o modal de confirmação
        }

        if (files) {
            const base64Promises = Array.from(files).map((file) =>
                getBase64(file)
            );

            Promise.all(base64Promises)
                .then((base64Array) => {
                    setBase64Files([...base64Files, ...base64Array]);
                    const newFileNames = Array.from(files).map(
                        (file) => file.name
                    );
                    setFileNames([...fileNames, ...newFileNames]);
                    props.onFileSelected([...base64Files, ...base64Array]);
                })
                .catch((error) => {
                    console.error("Error converting files to base64:", error);
                });
        }
    };

    const removeFile = (index: number) => {
        const newFileNames = fileNames.filter((_, i) => i !== index);
        const newBase64Files = base64Files.filter((_, i) => i !== index);

        setFileNames(newFileNames);
        setBase64Files(newBase64Files);
        props.onFileSelected(newBase64Files);
    };

    const confirmFileUpload = async () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file',  new Blob([selectedFile], { type: 'application/pdf' }), selectedFile.name);
    
            try {
                await uploadContract(formData);
                setMessage({ text: "Seu contrato foi enviado com sucesso!", color: "#50700F" });
                setTimeout(() => {
                    setMessage(null);
                }, 3000);
                setSelectedFile(null);
            } catch (error) {
                setMessage({ text: "Erro ao enviar o contrato. Tente novamente.", color: "#FF0033" });
                setTimeout(() => {
                    setMessage(null); // Limpa a mensagem após 3 segundos
                }, 3000);
            }

        } else {
            console.error("Nenhum arquivo selecionado.");
        }
        setModalOpen(false); // Fecha o modal
    };

    const closeModal = () => {
        setModalOpen(false); // Fecha o modal sem realizar o upload
    };

    return (
        <>
        <FileContainer onClick={chooseFile}>
            <input
                onChange={handleFile}
                type="file"
                style={{ display: "none" }}
                ref={inputRef}
                multiple={props.multiple}
                accept=".pdf"
            />

            <p>{props.title}</p>

            <img style={{ marginTop: 20 }} src={plusIcon} alt="Plus Icon" />
            <FileLabel>{t("choose-file")}</FileLabel>
            {message && (
                <div style={{ color: message.color, marginTop: 20 }}>
                    {message.text}
                </div>
            )}
            {fileNames.map((text, index) => (
                <FileNameContainer key={index}>
                    <p>
                        <RemoveIcon
                            onClick={(e: any) => {
                                e.stopPropagation();
                                setFileNames([]);
                                setSelectedFile(null);
                                // removeFile(index);
                            }}
                        >
                            {text}
                            &nbsp;- x
                        </RemoveIcon>
                    </p>
                </FileNameContainer>
            ))}
        </FileContainer>

        
        <ConfirmationModal 
            isOpen={modalOpen}
            onClose={closeModal}
            onConfirm={confirmFileUpload}
            title="Confirmação"
            message="Você confirmou que o arquivo está assinado?"
        />
      </>
    );
}



