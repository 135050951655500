import axios, { AxiosRequestConfig } from "axios";
import { useAuth } from "../context/AuthContext";
import {
    UserUpdateResponse,
    BankDataUserUpdateResponse,
    UserContractResponse,
} from "../models/UserResponse";
import { apiBase } from "../utils/server/api";
import { IUser } from "../models/User";
import { CoverageLocation } from '@/models/LocationData';

export type PartialUserUpdate = Partial<{
    fullname: string;
    phone: string;
    company_name: string;
    state_registration: string;
    password: string;
    address: string;
    zip_code: string;
    street: string;
    house_number: string;
    complement: string;
    neighborhood: string;
    federal_unity: string;
    city: string;
    lat: any;
    lng: any;
    engineer_document?: string;
    engineer_document_type?: string;
}>;

export type PartialBankDataUpdate = Partial<{
    pix_key: string;
    pix: string;
    agency: string;
    agency_prefix: string;
    account: string;
    bank: string;
    document: string;
    favored: string;
}>;

const updateUser =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) =>
    async (data: PartialUserUpdate) => {
        try {
            const response = await axios.put(`${apiBase}/user`, data, config());
            return response.data as UserUpdateResponse;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

const updateDataBankUser =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) =>
    async (data: PartialBankDataUpdate) => {
        try {
            const response = await axios.post(
                `${apiBase}/user/bank-data`,
                data,
                config()
            );
            return response.data as BankDataUserUpdateResponse;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

const getUser =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) => async () => {
        try {
            const response = await axios.get(`${apiBase}/user`, config());
            return response.data as UserUpdateResponse;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

const recoverPassword =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) =>
    async (data: { password: string; code?: string }) => {
        await axios
            .post(`${apiBase}/recover-password`, data, config())
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.error(error);
                throw error;
            });
    };

const acceptTermsOfUse =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) => async () => {
        await axios
            .put(`${apiBase}/user/accept-terms-of-use`, {}, config())
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.error(error);
                throw error;
            });
    };

const getContract =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) => async () => {
        try {
            const response = await axios.get(
                `${apiBase}/user/contracts`,
                config()
            );
            return response.data as UserContractResponse;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

const listEngineerCoverage =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) =>
    async (): Promise<CoverageLocation[]> => {
        try {
            const response = await axios.get(
                `${apiBase}/user/coverage-locations`,
                config(),
            );
            return response.data as CoverageLocation[];
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

const addEngineerCoverage =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) =>
    async (data: CoverageLocation) => {
        try {
            const response = await axios.post(
                `${apiBase}/user/coverage-locations`,
                data,
                config(),
            );
            return response.data as CoverageLocation;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

const removeEngineerCoverage =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) =>
    async (id: number) => {
        try {
            const response = await axios.delete(
                `${apiBase}/user/coverage-locations/${id}`,
                config(),
            );
            return response.data as CoverageLocation;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

export function useServiceUser() {
    const { axiosConfig } = useAuth();
    return {
        updateUser: updateUser(axiosConfig),
        updateDataBankUser: updateDataBankUser(axiosConfig),
        getUser: getUser(axiosConfig),
        recoverPassword: recoverPassword(axiosConfig),
        acceptTermsOfUse: acceptTermsOfUse(axiosConfig),
        getContract: getContract(axiosConfig),
        listEngineerCoverage: listEngineerCoverage(axiosConfig),
        addEngineerCoverage: addEngineerCoverage(axiosConfig),
        removeEngineerCoverage: removeEngineerCoverage(axiosConfig),
    };
}
